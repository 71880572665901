// env config
export const API_CONFIG = {
    API_URL: `${process.env.REACT_APP_API_HOST}/${process.env.REACT_APP_API_APP_ID}`,
    API_URL_NO_APP_ID: process.env.REACT_APP_API_HOST,
    API_ID: process.env.REACT_APP_API_APP_ID,
    API_KEY: process.env.REACT_APP_API_KEY,
    API_TIME_OUT: parseInt(process.env.REACT_APP_API_TIMEOUT || '0'),
    CLIENT_CREDENTIALS_INTERNAL: process.env.REACT_APP_CLIENT_CREDENTIALS_INTERNAL
};

// keys for localstorage
export const STORAGE_KEY = {
    ACCESS_TOKEN: 'oldst_accesstoken',
    SIDEBAR_STATE: 'sidebar-state'
};

// WEBFONT config
export const WEBFONT_CONFIG = {
    google: {
        families: [
            'Poppins:300',
            'Poppins:400',
            'Poppins:500',
            'Poppins:600',
            'Poppins:700',
            'Poppins:800',
            'Poppins:900'
        ]
    }
};

export const colorBrandActive = '#0069e6';
export const colorBrandGreen = '#25b862';

// services
export const services = {
    oAuthService: 'oauth2-service/',
    userService: 'user-service',
    authenticationService: '/authentication-service',
    coreService: '/core-service',
    databaseService: '/database-service',
    messageService: '/message-service',
    welfareService: '/welfare-service'
};

export const storageKey = {
    accessToken: 'accessToken'
};

export const grantType = {
    CLIENT_CREDENTIALS: 'client_credentials',
    REFRESH_TOKEN: 'refresh_token',
    PASSWORD: 'password'
};

export const mapBox = {
    api: process.env.REACT_APP_MAPBOX_API || 'https://api.mapbox.com/geocoding/v5/mapbox.places',
    key: process.env.REACT_APP_MAPBOX_KEY || 'pk.eyJ1IjoiYWxsZWdyIiwiYSI6ImNsMG1laGkzZzE0YzczYm05NzBsNXdibHgifQ.kF1clTa4BR01O5LkcO2Z_A'
};

export const webView = {
    pinboard: process.env.REACT_APP_WEBVIEW_PINBOARD,
    pinboardMobile: process.env.REACT_APP_WEBVIEW_PINBOARD_MOBILE,
    pinboardSupport: process.env.REACT_APP_WEBVIEW_PINBOARD_SUPPORT,
    events: process.env.REACT_APP_WEBVIEW_EVENTS,
    guid: process.env.REACT_APP_WEBVIEW_GUID,
    key: process.env.REACT_APP_WEBVIEW_KEY,
    manageEvents: process.env.REACT_APP_WEBVIEW_MANAGE_EVENTS,
    admin: process.env.REACT_APP_WEBVIEW_ADMIN,
    webviewSupportPinboard: process.env.REACT_APP_WEBVIEW_SUPPORT_PINBOARD,
    webviewTechSupport: process.env.REACT_APP_WEBVIEW_TECHNICAL_SUPPORT_FORM
};

export const registrationLink = process.env.REACT_APP_REGISTRATION_LINK;

export const elasticInfo = {
    api: process.env.REACT_APP_ELASTIC_API,
    key: process.env.REACT_APP_ELASTIC_API_KEY,
    registration: process.env.REACT_APP_REGISTRATION_ENDPOINT,
    createGroup: process.env.REACT_APP_CREATE_GROUP_ENDPOINT,
    questionResults: process.env.REACT_APP_SEND_QUESTION_RESULTS_ENDPOINT,
    eventDetails: process.env.REACT_APP_SEND_EVENT_DETAILS,
    pollResults: process.env.REACT_APP_SEND_POLL_RESULTS_ENDPOINT,
    bulkMessage: process.env.REACT_APP_SEND_EMAIL_BULK_INVITES,
    requestGroupCode: process.env.REACT_APP_REQUEST_GROUP_CODE,
    externalTermsAndCondition: process.env.REACT_APP_ACCEPTED_T_AND_C_ENDPOINT
};

export const firebaseConfig = {
    clientId: process.env.REACT_APP_FIREBASE_clientId,
    appId: process.env.REACT_APP_FIREBASE_appId,
    apiKey: process.env.REACT_APP_FIREBASE_apiKey,
    databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
    storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
    messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
    projectId: process.env.REACT_APP_FIREBASE_projectId,
    measurementId: process.env.REACT_APP_FIREBASE_measurementId
};

export const what3Words = {
    apiKey: process.env.WHAT3WORDS_API_KEY || 'FRGOQ1DB',
    api: process.env.WHAT3WORDS_API || 'https://api.what3words.com/v3/'
};

export const googleMaps = {
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY
};

export const dynamicLink = {
    firebaseApiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
    firebaseDynamicLink: process.env.REACT_APP_FIREBASE_DYNAMIC_LINK
};

export const MA_PINBOARD_ID = process.env.REACT_APP_MA_GROUP_ID;

export const RegionTypes = [
    'Country',
    'Postcode'
];

export const providerTypes = ['Charity', 'Not-For-Profit', 'Local Authority', 'Company', 'Government'];

export const serviceTypes = ['Army', 'Navy', 'Marines', 'Air Force'];

export const serviceSubTypes = ['Regular', 'Reservist'];

export const serviceStages = ['Serving', 'Veteran', 'Family Member'];

export const financialSupportId = process.env.REACT_APP_FINANCIAL_SUPPORT_ID;

export const questionTypes = ['Checklist', 'Currency', 'Date', 'Multi-Select', 'Multiple-Choice', 'Number', 'Text', 'Yes/No'];

export const DEFAULT_OPENING_HOURS = [
    {
        day: 'Monday',
        from_time: '08:00',
        to_time: '17:00'
    },
    {
        day: 'Tuesday',
        from_time: '08:00',
        to_time: '17:00'
    },
    {
        day: 'Wednesday',
        from_time: '08:00',
        to_time: '17:00'
    },
    {
        day: 'Thursday',
        from_time: '08:00',
        to_time: '17:00'
    },
    {
        day: 'Friday',
        from_time: '08:00',
        to_time: '17:00'
    },
    {
        day: 'Saturday',
        from_time: '08:00',
        to_time: '17:00',
        is_closed: true
    },
    {
        day: 'Sunday',
        from_time: '08:00',
        to_time: '17:00',
        is_closed: true
    }
];

export const welfareAccess = process.env.REACT_APP_ADMIN_ACCESS?.split('|');

export const thresholds = [
    'Less than',
    'Greater than',
    'Equal to',
    'Less than or equal to',
    'Greater than or equal to'
];

export const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const utilityBills = ['Electricity', 'Water', 'Internet', 'Cable/Satellite TV', 'Landline Phone'];

export const validIds = ['Passport', "Driver's License", 'National Identity Card', 'MOD90 (Military ID Card)', 'Veterans ID card', 'Veterans Rail Card'];

export const MAX_VOICE_RECORDING = process.env.REACT_APP_MAX_VOICE_RECORDING;

export const groupServingTypes = ['Serving', 'Veteran', 'Family'];

export const currentlyServingGroupQuestionId = process.env.REACT_APP_CURRENTLY_SERVING_GROUP_QUESTION;
