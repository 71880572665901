/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PrimaryGroup = {
    member_group?: any,
    serving_group?: any
};

const initialState: PrimaryGroup = {
    member_group: {},
    serving_group: {}
};

export const primaryGroupSlice = createSlice({
    name: 'primarygroup',
    initialState,
    reducers: {
        setMemberGroup: (state, action: PayloadAction<PrimaryGroup>) => {
            state.member_group = action.payload.member_group;
        },
        setServingGroup: (state, action: PayloadAction<PrimaryGroup>) => {
            state.serving_group = action.payload.serving_group;
        },
        clearPrimaryGroup: () => ({
            ...initialState
        })
    }
});

export const { setMemberGroup, clearPrimaryGroup, setServingGroup } = primaryGroupSlice.actions;

export default primaryGroupSlice.reducer;
