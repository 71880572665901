import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegionContact } from '~/types';

export type ContactsState = {
    contacts?: RegionContact[],
};

const initialState: ContactsState = {
    contacts: undefined
};

export const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setContacts: (state, action: PayloadAction<ContactsState>) => {
            state.contacts = action.payload.contacts;
        }
    }
});

export const { setContacts } = contactsSlice.actions;

export default contactsSlice.reducer;
