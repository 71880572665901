import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type QuestionAnswersState = {
    questionAnswers: any,
    isNewSet?: boolean
};

const initialState: QuestionAnswersState = {
    questionAnswers: {},
    isNewSet: false
};

export const questionAnswersSlice = createSlice({
    name: 'questionAnswers',
    initialState,
    reducers: {
        setQuestionAnswers: (state, action: PayloadAction<QuestionAnswersState>) => {
            if (action.payload.isNewSet) {
                state.questionAnswers = action.payload.questionAnswers;

                return;
            }

            state.questionAnswers = { ...(state.questionAnswers || {}), ...action.payload.questionAnswers };
        },
        clearQuestionAnswers: () => ({
            ...initialState
        })
    }
});

export const { clearQuestionAnswers, setQuestionAnswers } = questionAnswersSlice.actions;

export default questionAnswersSlice.reducer;
