import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CountriesState = {
    countries?: string[],
};

const initialState: CountriesState = {
    countries: undefined
};

export const countriesSlice = createSlice({
    name: 'countries',
    initialState,
    reducers: {
        setCountries: (state, action: PayloadAction<CountriesState>) => {
            state.countries = action.payload.countries;
        }
    }
});

export const { setCountries } = countriesSlice.actions;

export default countriesSlice.reducer;
