import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChecklistPath } from '~/types';

export type ChecklistState = {
    checklist: ChecklistPath[]
};

const initialState: ChecklistState = {
    checklist: []
};

export const checklistSlice = createSlice({
    name: 'checklist',
    initialState,
    reducers: {
        setChecklist: (state, action: PayloadAction<ChecklistState>) => {
            state.checklist = action.payload.checklist;
        },
        clearChecklist: () => ({
            ...initialState
        })
    }
});

export const { setChecklist, clearChecklist } = checklistSlice.actions;

export default checklistSlice.reducer;
