import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmergencySupport } from '~/types';

export type EmergencySupportState = {
    emergencySupports: EmergencySupport[]
};

const initialState: EmergencySupportState = {
    emergencySupports: []
};

export const emergencySupportSlice = createSlice({
    name: 'emergencySupport',
    initialState,
    reducers: {
        setEmergencySupports: (state, action: PayloadAction<EmergencySupportState>) => {
            state.emergencySupports = action.payload.emergencySupports;
        },
        clearEmergencySupports: () => ({
            ...initialState
        })
    }
});

export const { setEmergencySupports, clearEmergencySupports } = emergencySupportSlice.actions;

export default emergencySupportSlice.reducer;
