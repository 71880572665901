/* eslint-disable camelcase */
import { useHistory, useParams } from 'react-router-dom';
import useHref from './useHref';
import { LocationDescriptor } from 'history';

const useWebHistory = () => {
    const history = useHistory();
    const { app } = useHref();
    const { application_id, user_id }: any = useParams() || {};

    const goBack = () => {
        history.goBack();
    };

    const onGetPath = (path: string) => {
        return path;

        if (path.includes('app=')) return path;

        return path?.includes('?') ? `${path}&app=${app}` : `${path}?app=${app}`;
    };

    const push = (path: string | LocationDescriptor<any>, state?: any) => {
        const initialPath = application_id && user_id ? `/${application_id}/${user_id}` : '';

        let newPath = onGetPath(typeof path === 'string' ? path : path.pathname!);

        if (typeof path === 'string') {
            history.push(`${initialPath}${newPath}`, state);

            return;
        }

        newPath = onGetPath(`${path.pathname}${path?.search ? `?${path?.search}` : ''}`!);

        history.push({
            pathname: `${initialPath}${newPath}`,
            state: path.state
        });
    };

    const replace = (path: string | LocationDescriptor<any>, state?: any) => {
        let newPath = onGetPath(typeof path === 'string' ? path : path.pathname!);

        const initialPath = application_id && user_id ? `/${application_id}/${user_id}` : '';

        if (typeof path === 'string') {
            history.push(`${initialPath}${newPath}`, state);

            return;
        }

        newPath = onGetPath(`${path.pathname}${path?.search ? path?.search : ''}`!);

        history.push({
            ...path,
            search: '',
            pathname: `${initialPath}${newPath}`
        });
    };

    return {
        goBack,
        push,
        replace,
        listen: history.listen,
        history
    };
};

export default useWebHistory;
