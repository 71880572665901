import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ParentCategoryState = {
    parentCategories: any[]
};

const initialState: ParentCategoryState = {
    parentCategories: []
};

export const parentCategorySlice = createSlice({
    name: 'parentCategory',
    initialState,
    reducers: {
        setParentCategories: (state, action: PayloadAction<ParentCategoryState>) => {
            state.parentCategories = action.payload.parentCategories;
        },
        clearParentCategories: () => ({
            ...initialState
        })
    }
});

export const { setParentCategories, clearParentCategories } = parentCategorySlice.actions;

export default parentCategorySlice.reducer;
