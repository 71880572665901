import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GroupQuestionOptionsState = {
    groupQuestionOptions?: any[],
};

const initialState: GroupQuestionOptionsState = {
    groupQuestionOptions: []
};

export const groupQuestionOptionsSlice = createSlice({
    name: 'groupQuestionOptions',
    initialState,
    reducers: {
        setGroupQuestionOptions: (state, action: PayloadAction<GroupQuestionOptionsState>) => {
            state.groupQuestionOptions = action.payload.groupQuestionOptions;
        }
    }
});

export const { setGroupQuestionOptions } = groupQuestionOptionsSlice.actions;

export default groupQuestionOptionsSlice.reducer;
