/* eslint-disable camelcase */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuestionPage } from '~/types';

export type GroupQuestionState = {
    sub_category_group_questions?: QuestionPage[],
    provider_group_questions?: QuestionPage[]
};

const initialState: GroupQuestionState = {
    sub_category_group_questions: [],
    provider_group_questions: []
};

export const groupQuestionSlice = createSlice({
    name: 'groupQuestions',
    initialState,
    reducers: {
        setSubCategoryGroupQuestions: (state, action: PayloadAction<GroupQuestionState>) => {
            state.sub_category_group_questions = action.payload.sub_category_group_questions;
        },
        setProviderGroupQuestions: (state, action: PayloadAction<GroupQuestionState>) => {
            state.provider_group_questions = action.payload.provider_group_questions;
        },
        clearGroupQuestions: () => ({
            ...initialState
        }),
        clearSubCategoryGroupQuestions: (state) => {
            state.sub_category_group_questions = [];
        },
        clearProviderGroupQuestions: (state) => {
            state.provider_group_questions = [];
        }
    }
});

export const { setSubCategoryGroupQuestions, setProviderGroupQuestions, clearGroupQuestions, clearProviderGroupQuestions, clearSubCategoryGroupQuestions } = groupQuestionSlice.actions;

export default groupQuestionSlice.reducer;
