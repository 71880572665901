import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CostOfLiving } from '~/types';

export type CostOfLivingState = {
    costOfLivings: CostOfLiving[]
};

const initialState: CostOfLivingState = {
    costOfLivings: []
};

export const costOfLivingSlice = createSlice({
    name: 'costOfLiving',
    initialState,
    reducers: {
        setCostOfLivings: (state, action: PayloadAction<CostOfLivingState>) => {
            state.costOfLivings = action.payload.costOfLivings;
        },
        clearCostOfLivings: () => ({
            ...initialState
        })
    }
});

export const { setCostOfLivings, clearCostOfLivings } = costOfLivingSlice.actions;

export default costOfLivingSlice.reducer;
