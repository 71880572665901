import PageLoadable from 'components/modules/Loadable/PageLoadable';

const AsyncNotFound = PageLoadable({ loader: () => import('./NotFound') });
const AsyncLogin = PageLoadable({ loader: () => import('./Login') });
// const AsyncRegister = PageLoadable({ loader: () => import('./Register') });
const AsyncResetPassword = PageLoadable({ loader: () => import('./ResetPassword') });
// const AsyncNotifications = PageLoadable({ loader: () => import('./Notifications') });
const AsyncMaintenance = PageLoadable({ loader: () => import('./Maintenance') });
const AsyncInitialRoute = PageLoadable({ loader: () => import('./InitialRoute') });
const AsyncLoginPassword = PageLoadable({ loader: () => import('./LoginPassword') });
const AsyncLoginEmail = PageLoadable({ loader: () => import('./LoginEmail') });
const AsyncDashboard = PageLoadable({ loader: () => import('./Dashboard') });
const AsyncModerateSubCategory = PageLoadable({ loader: () => import('./ModerateSubCategory') });
const AsyncCategories = PageLoadable({ loader: () => import('./Categories') });
const AsyncSubCategories = PageLoadable({ loader: () => import('./SubCategories') });
const AsyncProviders = PageLoadable({ loader: () => import('./Providers') });
const AsyncModerateCategory = PageLoadable({ loader: () => import('./ModerateCategory') });
const AsyncModerateProviders = PageLoadable({ loader: () => import('./ModerateProviders') });
const AsyncCategory = PageLoadable({ loader: () => import('./Category') });
const AsyncEmergencySupport = PageLoadable({ loader: () => import('./EmergencySupport') });
const AsyncRegionalFocusDataInformation = PageLoadable({ loader: () => import('./RegionalFocusDataInformation') });
const AsyncRegionalFocusDataInformationPostCode = PageLoadable({ loader: () => import('./RegionalFocusDataInformationPostCode') });
const AsyncQuestions = PageLoadable({ loader: () => import('./Questions') });
const AsyncSummary = PageLoadable({ loader: () => import('./Summary') });
const AsyncPathwayComplete = PageLoadable({ loader: () => import('./PathwayComplete') });
const AsyncServiceDetails = PageLoadable({ loader: () => import('./ServiceDetails') });
const AsyncConfirmationID = PageLoadable({ loader: () => import('./ConfirmationID') });
const AsyncUtilityBill = PageLoadable({ loader: () => import('./UtilityBill') });
const AsyncSubCategoryProviderQuestions = PageLoadable({ loader: () => import('./SubCategoryProviderQuestions') });
const AsyncSubCategory = PageLoadable({ loader: () => import('./SubCategory') });
const AsyncGroups = PageLoadable({ loader: () => import('./Groups') });
const AsyncModerateGroups = PageLoadable({ loader: () => import('./ModerateGroups') });
const AsyncCostOfLivings = PageLoadable({ loader: () => import('./CostOfLivings') });
const AsyncManageEmergencySupport = PageLoadable({ loader: () => import('./ManageEmergencySupport') });
const AsyncParentCategory = PageLoadable({ loader: () => import('./ParentCategory') });
const AsyncCompleteSurvey = PageLoadable({ loader: () => import('./CompleteSurvey') });
// ROUTE IMPORT CODE GENERATOR INDICATOR DO NOT DELETE

const routes = {
    LOGIN_EMAIL: {
        component: AsyncLoginEmail,
        path: '/login/email',
        authedRoute: false
    },
    LOGIN_PASSWORD: {
        component: AsyncLoginPassword,
        path: '/login/password',
        authedRoute: false
    },
    LOGIN: {
        component: AsyncLogin,
        path: '/login',
        authedRoute: false
    },
    // REGISTER: {
    //     component: AsyncRegister,
    //     path: '/register',
    //     authedRoute: false
    // },
    RESET_PASSWORD: {
        component: AsyncResetPassword,
        path: '/reset-password',
        authedRoute: false
    },
    // NOTIFICATIONS: {
    //     component: AsyncNotifications,
    //     path: '/notifications',
    //     authedRoute: true
    // },
    MAINTENANCE: {
        component: AsyncMaintenance,
        path: '/maintenance',
        authedRoute: false
    },
    DASHBOARD: {
        component: AsyncDashboard,
        path: '/dashboard',
        authedRoute: true
    },
    MODERATE_SUB_CATEGORY: {
        component: AsyncModerateSubCategory,
        path: '/moderate-sub-categories',
        authedRoute: true
    },
    CATEGORIES: {
        component: AsyncCategories,
        path: '/categories',
        authedRoute: true
    },
    SUB_CATEGORIES: {
        component: AsyncSubCategories,
        path: '/sub-categories',
        authedRoute: true
    },
    PROVIDERS: {
        component: AsyncProviders,
        path: '/providers',
        authedRoute: true
    },
    MODERATE_CATEGORY: {
        component: AsyncModerateCategory,
        path: '/moderate-categories',
        authedRoute: true
    },
    MODERATE_PROVIDERS: {
        component: AsyncModerateProviders,
        path: '/moderate-providers',
        authedRoute: true
    },
    CATEGORY: {
        component: AsyncCategory,
        path: '/:application_id/:user_id/category',
        authedRoute: false
    },
    EMERGENCY_SUPPORT: {
        component: AsyncEmergencySupport,
        path: '/:application_id/:user_id/emergency-support',
        authedRoute: false
    },
    REGIONAL_FOCUS_DATA_INFORMATION: {
        component: AsyncRegionalFocusDataInformation,
        path: '/:application_id/:user_id/regional-information',
        authedRoute: false
    },
    REGIONAL_FOCUS_DATA_INFORMATION_POST_CODE: {
        component: AsyncRegionalFocusDataInformationPostCode,
        path: '/:application_id/:user_id/regional-information-postcode',
        authedRoute: false
    },
    QUESTIONS: {
        component: AsyncQuestions,
        path: '/:application_id/:user_id/question/:page',
        authedRoute: false
    },
    SUMMARY: {
        component: AsyncSummary,
        path: '/:application_id/:user_id/summary',
        authedRoute: false
    },
    PATHWAY_COMPLETE: {
        component: AsyncPathwayComplete,
        path: '/:application_id/:user_id/complete-pathway',
        authedRoute: false
    },
    SERVICE_DETAILS: {
        component: AsyncServiceDetails,
        path: '/:application_id/:user_id/service-details',
        authedRoute: false
    },
    CONFIRMATION_I_D: {
        component: AsyncConfirmationID,
        path: '/:application_id/:user_id/confirm-id',
        authedRoute: false
    },
    UTILITY_BILL: {
        component: AsyncUtilityBill,
        path: '/:application_id/:user_id/utility-bill',
        authedRoute: false
    },
    SUB_CATEGORY_PROVIDER_QUESTIONS: {
        component: AsyncSubCategoryProviderQuestions,
        path: '/questions',
        authedRoute: true
    },
    SUB_CATEGORY: {
        component: AsyncSubCategory,
        path: '/:application_id/:user_id/sub-category',
        authedRoute: false
    },
    GROUPS: {
        component: AsyncGroups,
        path: '/groups',
        authedRoute: true
    },
    MODERATE_GROUPS: {
        component: AsyncModerateGroups,
        path: '/moderate-groups',
        authedRoute: true
    },
    COST_OF_LIVINGS: {
        component: AsyncCostOfLivings,
        path: '/cost-of-living',
        authedRoute: true
    },
    MANAGE_EMERGENCY_SUPPORT: {
        component: AsyncManageEmergencySupport,
        path: '/manage-emergency-support',
        authedRoute: true
    },
    PARENT_CATEGORY: {
        component: AsyncParentCategory,
        path: '/:application_id/:user_id/parent-category',
        authedRoute: false
    },
    COMPLETE_SURVEY: {
        component: AsyncCompleteSurvey,
        path: '/:application_id/:user_id/complete-survey',
        authedRoute: false
    },
    // ROUTE ENTRY CODE GENERATOR INDICATOR DO NOT DELETE
    INITIAL_ROUTE: {
        component: AsyncInitialRoute,
        path: '/',
        authedRoute: false
    },
    NOT_FOUND: {
        component: AsyncNotFound,
        path: '*',
        authedRoute: false
    }
};

export default routes;
