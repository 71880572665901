import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type MemberState = {
    member: any
};

const initialState: MemberState = {
    member: {}
};

export const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        setMember: (state, action: PayloadAction<MemberState>) => {
            state.member = action.payload.member;
        },
        setPendingAnswer: (state, action: PayloadAction<string | null>) => {
            state.member = {
                ...state.member,
                pending_answer: action.payload
            };
        },
        clearMember: () => ({
            ...initialState
        })
    }
});

export const { setMember, clearMember, setPendingAnswer } = memberSlice.actions;

export default memberSlice.reducer;
