import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ProviderState = {
    providers: any[]
};

const initialState: ProviderState = {
    providers: []
};

export const providerSlice = createSlice({
    name: 'provider',
    initialState,
    reducers: {
        setProviders: (state, action: PayloadAction<ProviderState>) => {
            state.providers = action.payload.providers;
        },
        clearProviders: () => ({
            ...initialState
        })
    }
});

export const { setProviders, clearProviders } = providerSlice.actions;

export default providerSlice.reducer;
