import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CategoryState = {
    categories: any[]
};

const initialState: CategoryState = {
    categories: []
};

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setCategories: (state, action: PayloadAction<CategoryState>) => {
            state.categories = action.payload.categories;
        },
        clearCategories: () => ({
            ...initialState
        })
    }
});

export const { setCategories, clearCategories } = categorySlice.actions;

export default categorySlice.reducer;
